.error-body {
    font-family: "Times New Roman", serif;
    background-color: white;
    color: #000;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100vh;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100%;
}

.error-container {
    width: 80%;
    padding: 20px;
    background-color: white;
    text-align: left;
}

.error-container h2 {
    color: #ff0000;
    font-size: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.error-container h3 {
    color: #000;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.error-container p {
    max-width: 750px;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #000;
    text-indent: 0;
    margin-left: auto; 
    margin-right: auto;
}

.error-container p:hover {
    color: #000;
}

.error-container strong {
    color: #000;
    font-weight: bold;
    text-decoration: underline;
}

.error-container em {
    color: #000;
    font-style: italic;
}

.error-container a {
    color: #0000ff;
    text-decoration: underline;
}

.error-container a:hover {
    color: #ff0000;
}

.error-container ul {
    list-style-type: disc;
    padding-left: 40px;
}
