body {
  font-family: 'Courier New', Courier, monospace; 
  background-color: #1e1e1e;
  color: #dcdcdc;
  margin: 0;
  padding: 0;
}

.container {
  width: 750px;
  margin: 50px auto;
  padding: 30px;
  background-color: #2a2a2a;
  border: 5px solid #444;
  box-shadow: 0px 0px 20px #00ff00; 
  text-shadow: 2px 2px 2px #ff00ff; 
}

h2 {
  color: #ffcc00; 
  font-size: 2.5em;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center; 
}

h3 {
  color: #ff3399; 
  font-size: 1.8em;
  margin-top: 40px;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  transform: rotate(-2deg); 
}

p {
  line-height: 1.8;
  margin-bottom: 20px;
  text-indent: 20px; 
  transition: color 0.5s ease; 
  color: white;
}

p:hover {
  color: #ff00ff; 
}

strong {
  color: #ff0033;
  font-weight: bold;
  text-decoration: underline wavy #ff0033;
}

.review em {
  color: #33ffcc;
  font-style: italic;
  background-color: #222;
  padding: 2px 4px;
  border-radius: 3px; 
  box-shadow: 0px 0px 5px #00ffcc;
}

.blog-link-container a, .home-link-container a, .review a  {
  color: #00ffcc;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.5s ease;
}

.home-link-container a:hover {
  color: #ff3399;
  text-decoration: underline;
  text-shadow: 1px 1px 5px #ff3399;
}


.blog-link-container a:hover {
  color: #ff3399;
  text-decoration: underline;
  text-shadow: 1px 1px 5px #ff3399;
}

.review a:hover {
  color: #ff3399;
  text-decoration: underline;
  text-shadow: 1px 1px 5px #ff3399;
}



ul {
  list-style-type: square;
  padding-left: 30px;
  transform: rotate(2deg);
}

.home-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.home-container h2 {
  margin-bottom: 20px;
}

.home-container h3 {
  margin-bottom: 15px;
}

.home-container p {
  margin-bottom: 30px;
}

.home-container p strong {
  display: block;
  margin-bottom: 10px;
}

.home-container a {
  margin-left: 5px;
}

.home-container ul {
  padding-left: 20px;
  line-height: 1.8;
}

.home-container ul li {
  margin-bottom: 10px;
}

h4 {
  color: #00ff00;
  font-weight: bold;
}

.footnotes {
  color: white;
}

.footnotes li {
  margin-bottom: 10px;
}